<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ReportCanvasDiscussion',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    InputField: () => import('@/components/general/InputField'),
    SelectField: () => import('@/components/general/SelectField')
  },
  data () {
    return {
      formData: {
        dateInit: '',
        dateEnd: '',
        solutionType: null,
        solutions: [],
        learningPath: [],
        reportId: null
      },
      solutionTypeList: [],
      allSolutionsList: [],
      paths: [],
      filteredSolutionsList: []
    }
  },
  computed: {
    solutionsListPaginated () {
      return this.$store.getters.solutionsList
    }
  },
  validations: {
    formData: {
      solutions: {
        required
      }
    }
  },
  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsTypesList',
      'attemptGetLearningPathsList',
      'attemptGetSolutionsList',
      'attemptGetReportCanvasDiscussion',
      'attemptCheckReport',
      'attemptReportDownload'
    ]),
    getReportCanvasDiscussion () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.setFetching(true)
      this.attemptGetReportCanvasDiscussion(this.formData.solutions).then((response) => {
        this.reportId = response.data.id
        this.checkReport()
      }).catch(() => {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },
    getSolutionsTypesList () {
      this.setFetching(true)
      this.attemptGetSolutionsTypesList().then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            if (element.alias === 'curso_ead') {
              this.getSolutionsByType({ id: element.id })
            }
          })
          return true
        }
      })
    },
    getLearningPathsList () {
      return this.attemptGetLearningPathsList({ limit: 300 }).then(pagination => {
        if (pagination.data) {
          this.paths = pagination.data.map((element) => {
            return { text: element.name, value: element.id, solutions: element.learningPathSolutions }
          })
        }
      })
    },
    getSolutionsLearningPath (learningPath) {
      learningPath.solutions.map((solution) => {
        this.formData.solutions.push(solution.solution.id)
      })
    },
    getSolutions (pagination) {
      return this.attemptGetSolutionsList(pagination).then((paginationResponse) => {
        this.allSolutionsList.push(...this.solutionsListPaginated)
        if (paginationResponse.nextPage && paginationResponse.nextPage !== paginationResponse.lastPage) {
          pagination.page = paginationResponse.nextPage
          this.getSolutions(pagination)
        }
      })
    },
    getSolutionsByType (solutionType) {
      this.filteredSolutionsList = []
      this.allSolutionsList = []
      this.formData.solutions = []
      this.formData.learningPath = []
      this.getSolutions({
        filter: {
          solution_type: solutionType.id
        },
        order: { name: 'ASC' },
        limit: 9999
      }).finally(() => {
        this.allSolutionsList.forEach((solution) => {
          this.filteredSolutionsList.push({
            text: solution.name,
            value: solution.id
          })
        })
        this.setFetching(false)
      })
    },
    leave () {
      this.$router.push({ name: 'reports.index' })
    },
    downloadReport () {
      this.attemptReportDownload(this.reportId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          this.$t('reports.generate:report.canvas.discussion.list.file.name').toString()
        )
        document.body.appendChild(link)
        link.click()
      })
    },
    checkReport () {
      const interval = setInterval(() => {
        this.attemptCheckReport(this.reportId).then((response) => {
          if (response.data.status === 'generated') {
            this.setFetching(false)
            this.downloadReport()
            clearInterval(interval)
          } else if (response.data.status === 'failed') {
            this.setFetching(false)
            this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
            clearInterval(interval)
          }
        })
      }, 5000)
    }
  },
  created () {
    this.setFetching(true)
    Promise.all([
      this.getSolutionsTypesList(),
      this.getLearningPathsList()
    ]).finally(() => {
      this.setFetching(false)
    })
  }
}
</script>
<template>
  <div class="main-content reports-create">
    <content-header
      :title="$t('reports.generate:report.canvas.discussion.list.title')"
      light-theme
      fixed
    >
      <action
        slot="back"
        type="button"
        :text="$t('global:back.reports')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <action-bar slot="actionbar" />
    </content-header>
    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <h2
            class="solutions-create-title"
            v-html="$t('reports.generate:header.description')"
          />
        </div>
        <form class="form">
          <form-section :title="$t('global:form.filters')">
            <select-field
              v-model="formData.learningPath"
              :label="$t('global:form.learning.path')"
              :items="paths"
              multiple
              :select-all-option="true"
              :searcheable="true"
              @change="getSolutionsLearningPath"
            />
            <select-field
              v-if="filteredSolutionsList.length"
              v-model="formData.solutions"
              :label="$t('global:form.solution')"
              :items="filteredSolutionsList"
              :validation="$v.formData.solutions"
              :multiple="true"
              :select-all-option="true"
              :searcheable="true"
            />
          </form-section>
          <action
            slot="button"
            primary
            large
            fixed-width
            type="button"
            :text="$t('reports.generate:btn.generate')"
            @click="getReportCanvasDiscussion"
          />
        </form>
      </div>
    </div>
  </div>
</template>
<style src="@/assets/styles/themes/default/solutions.css"></style>
